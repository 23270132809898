import "./FeedBack.css"
const FeedBack=({setFeedBack})=>{
    return (
        <div className="FeedBackContainer">
            <h2>Elaborate your  complaint</h2>
            <div className="InputFieldContainer">
                <label>Please elaborate your problem in details</label>
                <textarea className="FeedBackText"  onChange={(e)=>{setFeedBack(e.target.value)}}/>
            </div>
        </div>
    )
}

export default FeedBack;