export const Question=[
    [
        {
            Q: "Did you respond to the free size consultation call of Kazarmax?",
            expectedAns: true,
        },
        {
            Q: "Did you choose a size other than the one suggested by the Sizing Expert?",
            expectedAns: false
        },
        {
            Q: "Did you face a sizing error in spite of ordering as per the Sizing Expert’s advice?",
            expectedAns: true
        }
    ],
    [
        {
            Q: "Did you receive a different product than the one you had ordered? "
        }
    ],
    [
        {
            Q: "Did you receive the delivered product in a physically damaged/ tampered state or the packaging tampered/soiled?"
        }
    ],
    [
        {
            Q: "Is some item/accessories missing from your delivered package?"
        }
    ]
]