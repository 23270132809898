// import logo from './logo.svg';
import './App.css';
import DashBoard from './Pages/DashBoard/DashBoard.js'
import CreateComplaintContainer from './Pages/CreateComplaint/CreateComplaintContainer/CreateComplaintContainer';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';


function App() {
  return (
    <div className="App">
       <Router>
            <Switch>
              <Route exact path='/' component={DashBoard}></Route>
        
              <Route exact path='/create' component={CreateComplaintContainer}></Route>
              
            </Switch>
       </Router>
    </div>
  );
}

export default App;
