import {Link} from 'react-router-dom';
import './TemplateOne.css'
import logo from '../../img/logo.png';
import detailimg from '../../img/product-details-icon.png';
import complaintimg from '../../img/complaint.png';
const TemplateOne=({children})=>{
    return(
    <div className="TemplateOneContainer">
        <div className="TemplateOneSidebar">
            <div className="TemplateOneBrandNameContainer">
               <a href="/" > <img src={logo} className="logo" alt="logo"/></a>
                </div>
            <div className="TemplateOneSidebarLinks">
                <div className="TemplateOneActive">
                    
                    <img className="icon" src={detailimg} alt="status"/>
                    <Link className="TemplateOneLink" to="/">Status of Old Complaints	</Link>
                    
                    
                    </div>
                <div>
               
             
                    
                    <Link to="/create" className="link" ><img className="icon" src={complaintimg} alt="iconcreate" /><span className="TemplateOneLink"> Create New Complaints  </span>	 </Link>
                    
                    
                    </div>
            </div>
        </div>
        <div className="TemplateOneDashBoardBody">{children}</div>
    </div>);
}

export default TemplateOne;