import "./InputField.css"
const InputField=({label,placeholder,setValue,type,maxlength,setnoq,val})=>{
    return(
        <div className="InputFieldContainer">
            <label>{label}</label>
            <input type={type} placeholder={placeholder} maxLength={maxlength} value={val} onChange={(e)=>{
                
                
                setValue((e.target.value).substring(0,100))
             
                
                }}/>
        </div>
    );
}

export default InputField;