import "./RadioButton.css"

const RadioButton=({label,val,setValue})=>{
    return(
        <div className="radioButtonContainer">
            <label className="radioButtonLabel">{label}</label>
            <div className="radioButtonGroup">
                <div className={`radioButton ${val===true?"radioButtonActive":""}`} onClick={()=>setValue(true)}>Yes</div>
                <div className={`radioButton ${val===false?"radioButtonActive":""}`} onClick={()=>setValue(false)}>No</div>
            </div>
        </div>
    )
}

export default RadioButton;
