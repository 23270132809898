import {useState} from 'react'
import TemplateOne from "../../Template/TemplateOne/TemplateOne";
import InputField from "../../UtilComponewnts/InputField/InputField";
import './DashBoard.css'


const DashBoard=()=>{
    //Store Order Number Here
    const [OrderNo,SetOrderNo]=useState("");
    const [review,setReview] = useState("");
   

   
 


    const handelSubmit=()=>{
        fetch(`https://strapi-oneshot.etark.in/kazarmaxes?Orderno=${OrderNo}`)
        .then(response => response.json())
        .then(data => {
            console.log(data);
            console.log(typeof(data));
            console.log(data['0'].Review)
            //setDataview(data)
            setReview(data['0'].Review);
            if(data['0'].Review===undefined){
                setReview("Don't worry, your complaint is being looked at by our experts. The resolution is under processing.")
            }
        }
        ).catch((err)=>{
            setReview("We can't find any complaint against this order number!")
        });
    }
    return(
        <TemplateOne>
            <div className="DashBordContainer">
                <div className="DashBoardGreetings">
                    <p className="DashBoardGreetingsHello">Hello <span>User</span></p>
                    <p className="DashBoardGreetingsWelcome">Welcome to DashBoard</p>
                    <p className="DashBoardGreetingsInstruction">Check your Complaints and Create new Ones</p>
                </div>
                <div className="DashBoardFromContainer">
                   <InputField label="Enter your Order Number" placeholder="E.g. NNNN" setValue={SetOrderNo}/>
                   <button className="DashBoardSubmitButton" onClick={handelSubmit}>Submit</button>
                   <p className="DashBoardMessage">{review}</p>
                </div>
            </div>
            
        </TemplateOne>);
}

export default DashBoard;