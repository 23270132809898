import './TemplateTwo.css'
const TemplateTwo=({children,header})=>{
    return (
        <div className="TemplateTwoContainer">
            <div className="TemplateTwoNav">
                <div className="TemplateTwoBrand">
             <a href="/"> <img src="logo.png" className="logo" alt="logomain"/></a>  
                </div>
                {
                    header.map((h,i)=>{
                        return(
                            <div key={i} className={`TemplateTwoNavLink ${header.length===i+1? "TemplateTwoNavLinkActive": ""}`}>
                                <p>{h}</p>
                            </div>
                        );
                    })
                }
            </div>
            {children}
        </div>
    );
}

export default TemplateTwo;