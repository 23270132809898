import InputField from '../../../UtilComponewnts/InputField/InputField.js'

import {useState} from 'react';
import "./ComplaintDetails.css"
const ComplaintDetails=({setOrderNo,setProductName,
                        setPrice, setIsOrderedKajarm,anything,
                        setEmail,setSize, size,setnoq,inany,inpro,insize,wrpro,dmpro,email,Price,OrderNo,ProductName,
                        IsOrderedKajarmax,m,y,setm,sety,setanything,setinany, setinpro,setinsize,setwrpro,setdmpro,
                        setProblemCode})=>{



                            const [check,setCheck]=useState(false);   

                            
    return (
        <div className="ComplaintDetailsContainer">
                <h1>Complaint Details</h1>
                <p className="ComplaintDetailsDescription">We just need few details about your complaint so that we can help you resolve it!</p>
                <div className="ComplaintDetailsColumnContainer">
                <div className="ComplainDetailsLeftContainer">
                    <div>
                        <InputField label="Enter Order Number" type="text" placeholder="E.g.NNNN" maxlength="200" setValue={setOrderNo} setnoq={setnoq} val={OrderNo}/>
                    </div>
                    <div>
                        <InputField label="Enter Product Name" type="text" placeholder="E.g. Samsung Galaxy" maxlength="200" setValue={setProductName} setnoq={setnoq} val={ProductName} />
                    </div>
                    <div>
                        <InputField label="Price (INR)" type="number" placeholder="E.g. Rs10000"  setValue={setPrice} setnoq={setnoq} val={Price} />
                    </div>
                    <div>
                    <div className="radioButtonContainer">
            <label className="radioButtonLabel">Did you order directly from www.kazarmax.com ?</label>
            <div className="radioButtonGroup">
                <div className={`radioButton ${IsOrderedKajarmax===true?"radioButtonActive":""}`} onClick={()=>{setIsOrderedKajarm(true); console.log('true')}}>Yes</div>
                <div className={`radioButton ${IsOrderedKajarmax===false?"radioButtonActive":""}`} onClick={()=>{setIsOrderedKajarm(false); console.log('false')}}>No</div>
            </div>
        </div>
                      
                    </div>
                </div>
                <div className="ComplainDetailsRightContainer">
                <div className="InputFieldContainer">
                <label>How long has it been since you bought the product?</label>
                <div className="dropdown">
                        <div>
                        <label className="dropvalue">
          
          <select value={y} className='box' onChange={(e)=>{sety(e.target.value)}}>        
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
        
          </select>  Years
        </label>
                        </div>
                        <div>
                        <label className="dropvalue">
        
          <select value={m} className='box' onChange={(e)=>{setm(e.target.value)}}>        
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="9">10</option>
            <option value="9">11</option>
         
        
          </select>  Months
        </label>
                        </div>

                    </div>

                    </div>
                  
            
                    <div>
                        <InputField type="email" label="Enter Email Id" maxlength="200" placeholder="E.g. jondoe@gmail.com" setValue={setEmail} setnoq={setnoq}  val={email}/>
                    </div>
                    <div>
                    <div className="InputFieldContainer">
            <label>Mention the size of the product</label>
            <input type="number" placeholder="E.g 8" value={size==null?'':size} maxLength="2"  onChange={(event)=>{
                
                if(event.target.value==='0' || event.target.value==='e'){
                   
                    event.target.value='';
                    
                }else{
                    setSize((event.target.value).substring(0,2));
                }
                    
                
                
                }}/>
        </div>      </div>{IsOrderedKajarmax?
                    <div>
                        <label>Mention your problem type</label>
                        <div className="radioCheck">
                            <input type="checkbox" name="problem" value={insize}  onChange={(e)=>{setProblemCode(1); setinsize(e.target.checked)}}/>
                            <label className="label">Incorrect Size</label>
                        </div>
                        <div className="radioCheck">
                            <input type="checkbox" name="problem2" value={wrpro} onChange={(e)=>{setProblemCode(2); setwrpro(e.target.checked)}}/>
                            <label className="label">Wrong product delivered</label>
                        </div>
                        <div className="radioCheck">
                            <input type="checkbox" name="problem3" value={dmpro} onChange={(e)=>{setProblemCode(3); setdmpro(e.target.checked) }}/>
                            <label className="label">Damaged product</label>
                        </div>
                        <div className="radioCheck">
                            <input type="checkbox" name="problem4" value={inpro}  onChange={(e)=>{setProblemCode(4); setinpro(e.target.checked) }}/>
                            <label className="label">Incomplete/Missing item</label>
                        </div>
                        <div className="radioCheck">
                            <input type="checkbox" name="problem5" value={inany}  onChange={(e)=>{setProblemCode(5); setCheck(e.target.checked); setinany(e.target.checked)
                            
                            
                            }}/>
                            <label className="label">Anything else  &nbsp;</label>
                            {
                                check? <input type="text" name="anything" value={anything}  maxLength="100" onChange={(e)=>{setanything(e.target.value)}}/>:null
                                
                            }
                            
                        </div>
                    </div>:null}
                </div>
                </div>
        </div>
    );
}

export default ComplaintDetails;