import InputField from '../../../UtilComponewnts/InputField/InputField.js'
import RadioButton from '../../../UtilComponewnts/RadioButton/RadioButton.js'
import {Question} from "./Questions.js"
import "./product.css";
const PreductDetails=({ProblemCode,Q1,Q2,Q3,Q4,Q5,Q6,Q7,Q8,Q9,setQ1,setQ2,setQ3,setQ4,setQ5,setQ6,setQ7,setQ8,setQ9,setPreSize,file,
    setSelectedFile,


    inany, inpro,insize, wrpro,dmpro



})=>{
    return (
        <div className="ComplaintDetailsContainer">
                <h1>Product Details</h1>
                <div className="ComplaintDetailsColumnContainer">
                <div className="ComplainDetailsLeftContainer">
                    {
                        insize?(
                        <div>
                            <RadioButton label={Question[0][0].Q} setValue={setQ1} val={Q1}/>
                            {Q1===true?<RadioButton label={Question[0][1].Q} setValue={setQ2} val={Q2}/>:null}
                            {Q2===false?<RadioButton label={Question[0][2].Q} setValue={setQ3} val={Q3}/>:null}
                            <InputField label="What size do you prefer" placeholder="E.g. 12" setValue={setPreSize}/>


                        </div>
                        ):null}

                        {wrpro?(<div>
                            {
                                <RadioButton label={Question[1][0].Q} setValue={setQ4} val={Q4}></RadioButton>
                            }
                        </div>):null}
                        
                      { dmpro?(<div>
                            {
                                <RadioButton label={Question[2][0].Q} setValue={setQ5} val={Q5}></RadioButton>
                            }
                        </div>):null}
                        
                        {
                        inpro?(<div>
                            {
                                <RadioButton label={Question[3][0].Q} setValue={setQ6} val={Q6}></RadioButton>
                            }
                        </div>):null}
                    
                </div>
                <div className="ComplainDetailsRightContainer">
                <div>
                        <RadioButton label="Are you reporting about your problem to us within 48 hours from the date of delivery?" setValue={setQ7} val={Q7}/>
                    </div>
                    <div>
                        <RadioButton label="Have you tried the product?" setValue={setQ8} val={Q8}/>
                    </div>
                    <div>
                        <RadioButton label="Is the product's original packaging box (box and tags) missing?" setValue={setQ9} val={Q9}/>
                    </div>   
                    <div > 
                        <label>Upload a clear visible photo of the received product (under 2mb)</label>
                        <div className="upload" >
                           <input type="file" name="upload" ng-model="image"   data-max-size="2048" onChange={(e) => {setSelectedFile(e.target.files[0]); 
                            
                            if(e.target.files[0].size>2097152){
                                e.target.value='';
                              alert("upload file within 2 MB");  
                            }
                            
                            }} /> 
                          
                        </div>
                        

                        
                        </div>      
                </div>
                </div>
        </div>
    )
}

export default PreductDetails;