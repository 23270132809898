import TemplateTwo from "../../../Template/TemplateTwo/TemplateTwo";
import ComplaintDetails from "../ComplaintDetails/ComplaintDetails";
import ProductDetails from "../ProductDetails/ProductDetails";
import FeedBack from "../FeedBack/FeedBack";
import "./CreateComplaintContainer.css";
import axios from "axios"
import {useState,useEffect} from 'react';
const CreateComplaintContainer=()=>{
    
    //Control Page
  
    const [done,setDone]=useState(false);
    const [style,setstyle]=useState("CreateComplaintContainerNextButton");
    const [pageNo,setPage]=useState(1);
    const [header,setHeader]=useState(["Complaint Details"]);
    // Product Details
    const [Q1,setQ1]=useState(null);
    const [Q2,setQ2]=useState(null);
    const [Q3,setQ3]=useState(null);
    const [Q4,setQ4]=useState(null);
    const [Q5,setQ5]=useState(null); 
    const [Q6,setQ6]=useState(null);
    const [Q7,setQ7]=useState(null);
    const [Q8,setQ8]=useState(null);
    const [Q9,setQ9]=useState(null);
    const [y,sety]=useState(0);  
    const [m,setm]=useState(0); 
    const [anything,setanything]=useState('');
    const [preSize,setPreSize]=useState(null);
    const [file,setSelectedFile]=useState();
    const [url,seturl]=useState('');
    const [noq,setnoq]=useState(0);
    // Order Details
    const [OrderNo,setOrderNo]=useState(null);
    const [ProductName,setProductName]=useState(null);
    const [insize,setinsize]=useState(false);
    const [wrpro,setwrpro]=useState(false);
    const [dmpro,setdmpro]=useState(false);
    const [inpro,setinpro]=useState(false);
    const [inany,setinany]=useState(false);
    const [Price,setPrice]=useState(null);
    const [IsOrderedKajarmax,setIsOrderedKajarm]=useState(null);
    const [email,setEmail]=useState(null);
    const [size,setSize]=useState(null);
    const [ProblemCode,setProblemCode]=useState(null);

    //FeedBack
    const [FeedBackval,setFeedBack]=useState(null);
    useEffect(()=>{
       
        if(pageNo===3){
            const formData=new FormData();
        formData.append("file",file);
        formData.append("upload_preset","ddtoml4u");
        axios.post("https://api.cloudinary.com/v1_1/oneshortwebsite/image/upload",formData).then((res)=>{
            console.log(res.data);
            console.log('test',typeof(res.data));
            console.log('test',res.data['url']);
            seturl(res.data['url'])
           
        })
        }
        
    },[file,pageNo])
    useEffect(()=>{
        if(noq===7 && pageNo===1){
          setstyle(".CreateComplaintReadytogoNextButton");
          setnoq(0);
        }
         if(pageNo===2 && noq===4 ){
            setstyle(".CreateComplaintReadytogoNextButton");
            setnoq(0);     
         
        }
    },[noq,pageNo])
    const setbackChange=()=>{
        if(pageNo===3){
            setPage(2);
           
            setHeader(["Complaint Details","Product Details"]);
        }else if(pageNo===2){
            setPage(1);
           

            setHeader(["Complaint Details"]);
        }else{
            window.location.href = '/';
        }
    }
    const setPageChange=()=>{
        if(pageNo===1){
            if(OrderNo && ProductName && Price && ((IsOrderedKajarmax===true && ProblemCode )|| IsOrderedKajarmax===false) && email && size ){
                if(IsOrderedKajarmax){
                    setPage(2);
                    setHeader(h=>[...h,"Product Details"]);
                }
                else {
                    setPage(3);
                    setHeader(h=>[...h,"Elaborate Details"]);
                }
            }else alert("Please select");
        }else if(pageNo===2){
           

            if(((Q1===true || Q1===false)||( Q2===true || Q2===false)||( Q3===true || Q3===false) || (Q4===true || Q4===false) || (Q5===true || Q5===false) || (Q6===true || Q6===false)) && ((Q7===true || Q7===false) || (Q8===true || Q8===false) || (Q9===true || Q9===false)||file)){
                   if(file){
                setPage(3);
                setHeader(h=>[...h,"Elaborate Details"]);
             }else{
                 alert("please upload photo");
             }
            }
            else{
                alert('please fill all the deatils');
            }
        }
    }

    const handelSubmit=()=>{
        
     

        console.log(Q1);
        console.log("hi");
        console.log(preSize)
        let score=0;
        let minus=0;
        let maxscore=0;
        if(Q1===true)score+=1;
        if(Q2===false)score+=1;
        if(Q3===true)score+=1;
        if(Q4===true)score+=1;
        if(Q5===true)score+=1;
        if(Q6===true)score+=1;
        if(Q7===true)score+=1;
        if(Q8===false)score+=1;
        if(Q9===false)score+=2;
        if(Q1===null)minus+=1;
        if(Q2===null)minus+=1;
        if(Q3===null)minus+=1;
        if(Q4===null)minus+=1;
        if(Q5===null)minus+=1;
        if(Q6===null)minus+=1;
        if(Q7===null)minus+=1;
        if(Q8===null)minus+=1;
        if(Q9===null)minus+=2;
        maxscore=10-minus;
        console.log(maxscore);
        // let sendBody={
        //     1:Q1,
        //     2:Q2,
        //     3:Q3,
        //     4:Q4,
        //     5:Q5,
        //     6:Q6,
        //     7:Q7,
        //     8:Q8,
        //     9:Q9  
        // }
        score = score.toString()
        const answers={
                "Q1": Q1,
                "Q2": Q2,
                "Q3": Q3,
                "Q4": Q4,
                "Q5": Q5,
                "Q6": Q6,
                "Q7": Q7,
                "Q8": Q8,
                "Q9": Q9,
                "year":y,
                "month":m,
                "Orderno": OrderNo,
                "anything":anything,
                "ProductName": ProductName,
                "Price": Price,
                "Email": email,
                "Size": size,
                "url":url,
                "maxscore":maxscore,
                "Detail":FeedBackval,
                "Score":score
           };
            console.log(answers)
            axios.post("https://strapi-oneshot.etark.in/kazarmaxes/",answers).then(()=>{
                console.log('done');
                setDone(true);

            }).catch(err=>{console.log(err)
            alert('please enter valid details');
            
            
            })
       
    }
    return(
        <TemplateTwo header={header}>
            {done?<div className="donepara">
                
                <img src="Group 61.png" className="icons" alt="tickmark"/>
                <p>
                Your complaint has been submitted.You can track the status of your 
                resolution by clicking on the complaint button on our home page by
                 entering your Order number.
            </p>
                </div>:
            
        
                    <div className="CreateComplaintContainerBody">
                        <div className="CreateComplaintContainerForm">


                            {pageNo===1?<ComplaintDetails m={m} y={y} size={size} OrderNo={OrderNo} ProductName={ProductName} anything={anything} Price={Price} email={email} setOrderNo={setOrderNo} setProductName={setProductName} setPrice={setPrice} setIsOrderedKajarm={setIsOrderedKajarm} setEmail={setEmail} setSize={setSize} IsOrderedKajarmax={IsOrderedKajarmax} setm={setm} setnoq={setnoq} sety={sety} setanything={setanything}  setProblemCode={setProblemCode} setinany={setinany} setinpro={setinpro} setinsize={setinsize} setwrpro={setwrpro} setdmpro={setdmpro} inany={inany} inpro={inpro} insize={insize} wrpro={wrpro} dmpro={dmpro}/>:pageNo===2?
                            <ProductDetails setSelectedFile={setSelectedFile} ProblemCode={ProblemCode} anything={anything} m={m} y={y} Q1={Q1} Q2={Q2} Q3={Q3} Q4={Q4} Q5={Q5} Q6={Q6} Q7={Q7} Q8={Q8} Q9={Q9} setnoq={setnoq} file={file}  setQ1={setQ1} setQ2={setQ2} setQ3={setQ3} setQ4={setQ4} setQ5={setQ5} setQ6={setQ6} setQ7={setQ7} setQ8={setQ8} setQ9={setQ9} setm={setm} sety={sety} setanything={setanything}  setPreSize={setPreSize} inany={inany} inpro={inpro} insize={insize} wrpro={wrpro} dmpro={dmpro}/>:<FeedBack setFeedBack={setFeedBack}  />}
                        </div>
                    </div>}
                    {
                        done?<div className="CreateComplaintContainerFooterButton">
                       <a href="/"><button className="CreateComplaintContainerSubmitButton" >Return to home page</button></a> 
                        </div>:
                pageNo<=2?
                (<div className="CreateComplaintContainerFooterButton">
                        <button className={style} onClick={setPageChange}>Next</button>
                        <button className="CreateComplaintContainerNextButton" onClick={setbackChange}>Back</button>
                        <a href="/"> <button className="CreateComplaintContainerNextButton">Cancel Application</button></a>
                </div>):(
                    <div className="CreateComplaintContainerFooterButton">
                    <button className="CreateComplaintContainerSubmitButton" onClick={handelSubmit}>Submit</button>
                   
                    </div>
                )
                    }
                   
               
        </TemplateTwo>
    );
}

export default CreateComplaintContainer;
